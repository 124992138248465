import { Fancybox } from "@fancyapps/ui";
import { api } from "./components/api";
import Cart, { CartCheckout } from "./components/cart";
import "./components/map";
import Header from "./components/header";
import { allowNumbersOnly } from "./components/utils";
import { mapContactsInit, mapInit } from "./components/map";
import { FormBook, FormCheckout, FormReview } from "./components/forms";

const $navbarToggle = document.querySelector('.header__toggle')
const menu = document.querySelector('.menu')
const $programmToggles = document.querySelectorAll('.program__toggle')


if ($navbarToggle) {
    $navbarToggle.onclick = () => {
        $navbarToggle.classList.toggle('active')
    }
}

if (menu) {
    window.addEventListener('scroll', () => {
        let y = window.scrollY;

        if (y > 72 && !menu.classList.contains('fixed')) {
            menu.classList.add('fixed')
        }

        if (y < 52) {
            menu.classList.remove('fixed')
        }
    });
}




if ($programmToggles) {
    $programmToggles.forEach(t => {
        t.onclick = () => {
            t.classList.toggle('active')
        }
    })
}



const filterToggle = document.querySelector('.main__filter-toggle')
if (filterToggle) {
    filterToggle.onclick = () => {
        filterToggle.classList.toggle('active')
    }
}




if (document.querySelector('.book-form')) {

    FormBook.init()
}


if (document.querySelector('.review-form')) {
    FormReview.init()

}

if (document.querySelector('.checkout-form')) {
    FormCheckout.init()

}



if (document.querySelector('.slider')) {
    Fancybox.bind(document.querySelector(".place__slider"), "[data-fancybox]", {
        // Your custom options
        wheel: "slide"
    });

    $('.slider').slick({
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: '<button type="button" class="slider__prev"><i class="_icon-keyboard_arrow_left" aria-hidden="true"></i></button>',
        nextArrow: '<button type="button" class="slider__next"><i class="_icon-keyboard_arrow_right" aria-hidden="true"></i></button>',

        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 1050,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 500,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },

            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });

}

if (document.querySelector('.galery')) {
    Fancybox.bind(document.querySelector(".galery"), "[data-fancybox]", {
        // Your custom options
        wheel: "slide"
    });


}



const gmap = document.getElementById("map")
if (gmap) {
    const loc = JSON.parse(gmap.dataset.location);
    const title = gmap.dataset.title;

    mapInit(title, parseFloat(loc.lat), parseFloat(loc.lng))
}

const gmapContacts = document.getElementById("map-contacts")
if (gmapContacts) {
    const loc = JSON.parse(gmapContacts.dataset.location);

    mapContactsInit(parseFloat(loc.lat), parseFloat(loc.lng))
}


Cart.init();
Header.init();

if (document.querySelector('.cart--checkout')) {

    CartCheckout.open()

}

