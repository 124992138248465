import { api } from "./api";
import Header from "./header";
import { allowNumbersOnly, debounce } from "./utils";

let Cart = {
    cart: document.querySelector('.cart:not(.cart--checkout)'),
    closeCart: document.querySelector('.cart:not(.cart--checkout)').querySelector('.cart__header > i'),

    init: async function () {
        this.closeCart.onclick = () => {
            this.close()
        };

        this.clear();

    },

    clear: function () {
        this.cart.querySelector('.cart__content').innerHTML = "";
        this.cart.querySelector('.cart__footer').classList.add('hide')
    },

    open: async function () {
        const cart = await api.getCart();

        if (!cart) {
            this.cart.classList.add('visible')
            this.cart.querySelector('.cart__content').insertAdjacentHTML('beforeend', '<p>Ваша корзина пуста...</p>')
            return
        };


        this.clear();

        cart.forEach(c => {
            this.addItem(c)
        })

        this.initItems(cart)
        calcTotal(this.cart)
        this.cart.querySelector('.cart__footer').classList.remove('hide')
        this.cart.classList.add('visible');
        document.querySelector('body').classList.add('noscroll');

    },

    close: function () {
        this.cart.classList.remove('visible');
        document.querySelector('body').classList.remove('noscroll');

        if (document.querySelector('.cart--checkout')) {
            CartCheckout.open()
        }

    },



    addItem: function (c) {
        this.cart.querySelector('.cart__content').insertAdjacentHTML('beforeend', create_cartitem(c))
    },

    initItems: function (db) {
        const cartItems = this.cart.querySelectorAll('.cart__item');

        cartItems.forEach((c, index) => {
            const cartRow = c.querySelectorAll('.item-cart__row');
            let btnMinus = cartRow[0].querySelector('.counter__btn.minus');
            let btnPlus = cartRow[0].querySelector('.counter__btn.plus');
            let input = cartRow[0].querySelector('.counter__input');
            let sum = cartRow[0].querySelector('.item-cart__sum');
            let subTotal = c.querySelector('.item-cart__footer-sum');
            let cartRemove = c.querySelector('.item-cart__remove');
            let price = db[index]["price_adult"];


            cartRemove.onclick = () => { removeItem(this.cart) };
            btnMinus.onclick = minus(input, btnMinus, sum, price, this.cart)
            btnPlus.onclick = plus(input, btnMinus, sum, price, this.cart)
            input.onkeypress = allowNumbersOnly;
            input.onkeyup = keyUP(input, btnMinus, sum, price, this.cart);


            btnMinus = cartRow[1].querySelector('.counter__btn.minus');
            btnPlus = cartRow[1].querySelector('.counter__btn.plus');
            input = cartRow[1].querySelector('.counter__input');
            sum = cartRow[1].querySelector('.item-cart__sum');
            price = db[index]["price_child"];

            btnMinus.onclick = minus(input, btnMinus, sum, price, this.cart)
            btnPlus.onclick = plus(input, btnMinus, sum, price, this.cart)
            input.onkeypress = allowNumbersOnly;
            input.onkeyup = keyUP(input, btnMinus, sum, price, this.cart);

            const removeItem = async (cartElement) => {

                const resApi = await api.deleteBooking(db[index]["id"]);

                if (resApi.message == 'ok') {
                    c.remove();

                    if (resApi.cnt == '0') {
                        Header.hideValue();
                        Header.setValue('');
                        cartElement.querySelector('.cart__content').insertAdjacentHTML('beforeend', '<p>Ваша корзина пуста...</p>')
                        cartElement.querySelector('.cart__footer').classList.add('hide')
                        return
                    }

                    Header.setValue(resApi.cnt);
                    calcTotal(cartElement)

                }
            }

            function calcSubtotal() {
                subTotal.textContent = parseInt(cartRow[0].querySelector('.item-cart__sum').textContent) +
                    parseInt(cartRow[1].querySelector('.item-cart__sum').textContent)

            }

            async function updateBooking(cartElement) {
                let adult = parseInt(cartRow[0].querySelector('.counter__input').value)
                let child = parseInt(cartRow[1].querySelector('.counter__input').value)
                let sum_ = parseInt(cartRow[0].querySelector('.item-cart__sum').textContent) +
                    parseInt(cartRow[1].querySelector('.item-cart__sum').textContent);

                const resApi = await api.updateBooking(db[index]["id"], adult, child, sum_);

                if (resApi.message == "ok") {
                    calcSubtotal();
                    calcTotal(cartElement);
                }

            }


            let updateBookingDebounce = debounce(updateBooking, 500);

            function minus(input, btnMinus, sum, price, cartElement) {

                return function () {
                    const count = parseInt(input.value) || 0;

                    input.value = count - 1;

                    sum.textContent = price * parseInt(input.value)
                    updateBookingDebounce(cartElement);

                    if (count - 1 == 0) {
                        btnMinus.classList.add('counter__btn--disabled');
                    }
                }
            }

            function plus(input, btnMinus, sum, price, cartElement) {
                return function () {
                    const count = parseInt(input.value) || 0;

                    input.value = count + 1;
                    sum.textContent = price * parseInt(input.value)


                    updateBookingDebounce(cartElement);


                    if (count + 1 > 0) {
                        btnMinus.classList.remove('counter__btn--disabled');
                    }
                }
            }

            function keyUP(input, btnMinus, sum, price, cartElement) {

                return function () {
                    if (input.value == '') {
                        input.value = 0;
                    } else {
                        input.value = parseInt(input.value);

                    }

                    const count = parseInt(input.value);
                    sum.textContent = price * count

                    updateBookingDebounce(cartElement);


                    if (count > 0) {
                        btnMinus.classList.remove('counter__btn--disabled');
                    } else {
                        btnMinus.classList.add('counter__btn--disabled');
                    }
                }
            }

        })

    },


}

export let CartCheckout = {
    cart: document.querySelector('.cart--checkout'),


    clear: function () {
        this.cart.querySelector('.cart__content').innerHTML = "";
        this.cart.querySelector('.cart__footer').classList.add('hide')
    },

    open: async function () {

        this.clear();

        const cart = await api.getCart();

        if (!cart) {
            this.cart.querySelector('.cart__content').insertAdjacentHTML('beforeend', '<p>Ваша корзина пуста...</p>')
            if (document.querySelector(".checkout-form .btn--submit")) {
                document.querySelector(".checkout-form .btn--submit").disabled = true;
            }
            return
        };


        this.clear();

        cart.forEach(c => {
            this.addItem(c)
        })

        this.initItems(cart)
        calcTotal(this.cart)
        this.cart.querySelector('.cart__footer').classList.remove('hide')

    },

    close: function () {
        this.cart.classList.remove('visible');
        document.querySelector('body').classList.remove('noscroll');
    },



    addItem: function (c) {
        this.cart.querySelector('.cart__content').insertAdjacentHTML('beforeend', create_cartitem(c))
    },

    initItems: function (db) {
        const cartItems = this.cart.querySelectorAll('.cart__item');

        cartItems.forEach((c, index) => {
            const cartRow = c.querySelectorAll('.item-cart__row');
            let btnMinus = cartRow[0].querySelector('.counter__btn.minus');
            let btnPlus = cartRow[0].querySelector('.counter__btn.plus');
            let input = cartRow[0].querySelector('.counter__input');
            let sum = cartRow[0].querySelector('.item-cart__sum');
            let subTotal = c.querySelector('.item-cart__footer-sum');
            let cartRemove = c.querySelector('.item-cart__remove');
            let price = db[index]["price_adult"];


            cartRemove.onclick = () => { removeItem(this.cart) };
            btnMinus.onclick = minus(input, btnMinus, sum, price, this.cart)
            btnPlus.onclick = plus(input, btnMinus, sum, price, this.cart)
            input.onkeypress = allowNumbersOnly;
            input.onkeyup = keyUP(input, btnMinus, sum, price, this.cart);


            btnMinus = cartRow[1].querySelector('.counter__btn.minus');
            btnPlus = cartRow[1].querySelector('.counter__btn.plus');
            input = cartRow[1].querySelector('.counter__input');
            sum = cartRow[1].querySelector('.item-cart__sum');
            price = db[index]["price_child"];

            btnMinus.onclick = minus(input, btnMinus, sum, price, this.cart)
            btnPlus.onclick = plus(input, btnMinus, sum, price, this.cart)
            input.onkeypress = allowNumbersOnly;
            input.onkeyup = keyUP(input, btnMinus, sum, price, this.cart);

            const removeItem = async (cartElement) => {

                const resApi = await api.deleteBooking(db[index]["id"]);

                if (resApi.message == 'ok') {
                    c.remove();

                    if (resApi.cnt == '0') {
                        Header.hideValue();
                        Header.setValue('');
                        cartElement.querySelector('.cart__content').insertAdjacentHTML('beforeend', '<p>Ваша корзина пуста...</p>')
                        if (document.querySelector(".checkout-form .btn--submit")) {
                            document.querySelector(".checkout-form .btn--submit").disabled = true;
                        }
                        cartElement.querySelector('.cart__footer').classList.add('hide')
                        return
                    }

                    Header.setValue(resApi.cnt);
                    calcTotal(cartElement)

                }
            }

            function calcSubtotal() {
                subTotal.textContent = parseInt(cartRow[0].querySelector('.item-cart__sum').textContent) +
                    parseInt(cartRow[1].querySelector('.item-cart__sum').textContent)

            }

            async function updateBooking(cartElement) {
                let adult = parseInt(cartRow[0].querySelector('.counter__input').value)
                let child = parseInt(cartRow[1].querySelector('.counter__input').value)
                let sum_ = parseInt(cartRow[0].querySelector('.item-cart__sum').textContent) +
                    parseInt(cartRow[1].querySelector('.item-cart__sum').textContent);

                const resApi = await api.updateBooking(db[index]["id"], adult, child, sum_);

                if (resApi.message == "ok") {
                    calcSubtotal();
                    calcTotal(cartElement);
                }

            }


            let updateBookingDebounce = debounce(updateBooking, 500);

            function minus(input, btnMinus, sum, price, cartElement) {

                return function () {
                    const count = parseInt(input.value) || 0;

                    input.value = count - 1;

                    sum.textContent = price * parseInt(input.value)
                    updateBookingDebounce(cartElement);

                    if (count - 1 == 0) {
                        btnMinus.classList.add('counter__btn--disabled');
                    }
                }
            }

            function plus(input, btnMinus, sum, price, cartElement) {
                return function () {
                    const count = parseInt(input.value) || 0;

                    input.value = count + 1;
                    sum.textContent = price * parseInt(input.value)


                    updateBookingDebounce(cartElement);


                    if (count + 1 > 0) {
                        btnMinus.classList.remove('counter__btn--disabled');
                    }
                }
            }

            function keyUP(input, btnMinus, sum, price, cartElement) {

                return function () {
                    if (input.value == '') {
                        input.value = 0;
                    } else {
                        input.value = parseInt(input.value);

                    }

                    const count = parseInt(input.value);
                    sum.textContent = price * count

                    updateBookingDebounce(cartElement);


                    if (count > 0) {
                        btnMinus.classList.remove('counter__btn--disabled');
                    } else {
                        btnMinus.classList.add('counter__btn--disabled');
                    }
                }
            }

        })

    },


}


function calcTotal(cartElement) {
    let sum = 0;
    const cartItems = cartElement.querySelectorAll('.cart__item');
    cartItems.forEach(c => {
        const subTotal = c.querySelector('.item-cart__footer-sum').textContent;
        sum += parseInt(subTotal);
    })

    cartElement.querySelector('.cart__total-value').textContent = sum;
}

function create_cartitem(c) {
    const item = `
    <div class="cart__item item-cart">
    <div class="item-cart__title">
        <div class="item-cart__foto"></div>
        <div class="item-cart__details">
            <div class="item-cart__name">${c["name"]}</div>
            <div class="item-cart__programm">${c["program_name"]}</div>
        </div>
        <div class="item-cart__remove">
            <i class="_icon-add">
            </i>
        </div>

    </div>
    <div class="item-cart__content">
        <div class="item-cart__row">
            <div class="item-cart__label">Взрослый</div>
            <div class="item-cart__label">${c["price_adult"]}</div>
            <div class="item-cart__counter counter-sm">
                <div class="counter">
                    <i class="_icon-minus counter__btn minus">
                    </i>
                    <input type="text" class="counter__input" value=${c["adult"]}>
                    <i class="_icon-add counter__btn plus">
                    </i>
                </div>
            </div>
            <div class="item-cart__sum">
            ${c["price_adult"] * c["adult"]}
            </div>
        </div>
        <div class="item-cart__row">
            <div class="item-cart__label">Детский</div>
            <div class="item-cart__label">${c["price_child"]}</div>
            <div class="item-cart__counter counter-sm">
                <div class="counter">
                    <i class="_icon-minus counter__btn minus">
                    </i>
                    <input type="text" class="counter__input" value=${c["child"]}>
                    <i class="_icon-add counter__btn plus">
                    </i>
                </div>
            </div>
            <div class="item-cart__sum">
              ${c["price_child"] * c["child"]}
            </div>
        </div>
    </div>
    <div class="item-cart__footer">
        <div class="item-cart__footer-title">Стоимость</div>
        <div class="item-cart__footer-sum">${c["sum"]}</div>
    </div>
</div>
    `
    return item;
}


export default Cart