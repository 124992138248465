import { api } from "./api";
import Cart from "./cart";

let Header = {
    header: document.querySelector('.header'),
    toggle: document.querySelector('.header__cart'),
    value: document.querySelector('.header__cart-value'),
  
    hideValue: function () {
        this.value.classList.add('hide');
    },
  
    showValue: function () {
        this.value.classList.remove('hide');
    },
  
    setValue: function (val) {
        this.value.textContent = val;
    },

    init: async function () {
        this.toggle.onclick =()=>{ Cart.open()}

        const cart = await api.getCart();


        if (!cart) {
            Header.hideValue();
            return
        };

        Header.setValue(cart.length);
        
    },


}

export default Header