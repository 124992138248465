export const api = {
    updateCart:async  function updateCart(adult, child, id) {
        let formData = new FormData();
        formData.append('adult', adult);
        formData.append('child', child);
        formData.append('id', id);
        formData.append('action', 'update');
        const res = await fetch('/booking', {
            method: 'POST',
            credentials: "same-origin",
            body: formData
        });

        if (!res.ok) {
            throw new Error('Error occurred!')
        };

        console.log( res.json());

        return 'ok';

    },

    addReview:async  function addReview(ex_slug, name, email,rating,text) {
        let formData = new FormData();
        formData.append('ex_slug', ex_slug);
        formData.append('name', name);
        formData.append('email', email);
        formData.append('rating', rating);
        formData.append('text', text);
        const res = await   fetch('/review', {
            method: 'POST',
            body: formData
        });

        if (!res.ok) {
            throw new Error('Error occurred!')
        };
        return res.json();

    },

    addBooking:async  function addBooking(ex_slug, child, adult,program_id,program_name,sum) {
        let formData = new FormData();
        formData.append('ex_slug', ex_slug);
        formData.append('child', child);
        formData.append('action', 'add');
        formData.append('adult', adult);
        formData.append('sum', sum);
        formData.append('program_id', program_id);
        formData.append('program_name', program_name);
        const res = await   fetch('/booking', {
            method: 'POST',
            body: formData
        });

        if (!res.ok) {
            throw new Error('Error occurred!')
        };

        return await res.json();

    },

    addCheckout:async  function addBooking( connect, address, payment, email,
        name, phone) {
        let formData = new FormData();
        formData.append('connect', connect);
        formData.append('address', address);
        formData.append('payment', payment);
        formData.append('email', email);
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('action', 'checkout');
        const res = await   fetch('/booking', {
            method: 'POST',
            body: formData
        });

        if (!res.ok) {
            throw new Error('Error occurred!')
        };

        return await res.json();

    },

    updateBooking:async  function updateBooking(id, adult,child,sum) {
        let formData = new FormData();
        formData.append('id', id);
        formData.append('child', child);
        formData.append('action', 'update');
        formData.append('adult', adult);
        formData.append('sum', sum);
        const res = await   fetch('/booking', {
            method: 'POST',
            body: formData
        });

        if (!res.ok) {
            throw new Error('Error occurred!')
        };


        return await res.json();
        
    },

    deleteBooking:async  function deleteBooking(id) {
        let formData = new FormData();
        formData.append('id', id);
        formData.append('action', 'delete');
        const res = await   fetch('/booking', {
            method: 'POST',
            body: formData
        });

        if (!res.ok) {
            throw new Error('Error occurred!')
        };


        return await res.json();
        


    },

    getCart:async  function getCart() {

        const res = await fetch('/booking', {
            method: 'GET',
            credentials: "same-origin"
        });

        if (!res.ok) {
            throw new Error('Error occurred!')
        };

        return res.json();
    }
}