// Initialize and add the map
let map;

export async function mapInit(title, lat, lng) {

    // The location of Uluru
    const position = { lat: lat, lng: lng };
    const beachFlagImg = document.createElement("img");

    beachFlagImg.src =
        "/images/beachflag.png";


    // Request needed libraries.
    //@ts-ignore
    const { Map, InfoWindow } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

    // The map, centered at Uluru
    map = new Map(document.getElementById("map"), {
        zoom: 15,
        center: position,
        mapId: "DEMO_MAP_ID",
    });

    const infoWindow = new InfoWindow();

    // The marker, positioned at Uluru
    const marker = new AdvancedMarkerElement({
        map: map,
        position: position,
        title: title,
        content: beachFlagImg,
        gmpClickable: true,
    });

    marker.addListener("click", ({ domEvent, latLng }) => {
        const { target } = domEvent;

        infoWindow.close();
        infoWindow.setContent(marker.title);
        infoWindow.open(marker.map, marker);
        map.panTo(latLng);
    });
}

let mapContacts;

export async function mapContactsInit(lat, lng) {
    let width = window.innerWidth;


    // The location of Uluru
    const position = { lat: lat, lng: lng };

    // Request needed libraries.
    //@ts-ignore
    const { Map, InfoWindow } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

    // The map, centered at Uluru
    mapContacts = new Map(document.getElementById("map-contacts"), {
        zoom: 15,
        center: position,
        mapId: "DEMO_MAP_ID",
    });

    if(width>850){
        let ov = new google.maps.OverlayView();
        ov.onAdd = function () {
            let proj = this.getProjection();
            let point = proj.fromLatLngToContainerPixel(position);
            point.x -= 150;
            mapContacts.panTo(proj.fromContainerPixelToLatLng(point));
        };
        ov.draw = function () { };
        ov.setMap(mapContacts);
    }



    // The marker, positioned at Uluru
    const marker = new AdvancedMarkerElement({
        map: mapContacts,
        position: position,
        title: "Bangkok"
    });



    // console.log(proj)
    // console.log(proj.fromLatLngToContainerPixel(marker.position))


}