import { api } from "./api"
import Cart from "./cart"
import Header from "./header"
import { allowNumbersOnly, validEmail } from "./utils"

let FormReview = {
    form: document.querySelector('.review-form'),
    rating: document.querySelector('.review-form .rating'),
    ratingValue: document.querySelector('.review-form .rating__value'),
    name: document.querySelector('input[name="name"]'),
    email: document.querySelector('input[name="email"]'),
    text: document.querySelector('textarea[name="text"]'),

    init: async function () {
        this.name.oninput = (e) => {
            e.currentTarget.closest('.form__input').classList.remove('error')
        }

        this.email.oninput = (e) => {
            e.currentTarget.closest('.form__input').classList.remove('error')
        }

        this.text.oninput = (e) => {
            e.currentTarget.closest('.form__input').classList.remove('error')
        }

        this.form.addEventListener('submit', async (e) => {
            e.preventDefault();
            let error = false;

            if (!validEmail.test(this.email.value) && this.email.value !== '') {
                error = true;
                this.form.querySelector('.form__input--email').classList.add('error')
                this.form.querySelector('.form__input--email .form__error').textContent = 'введите правильный email'
            };

            if (this.email.value == '') {
                error = true;
                this.form.querySelector('.form__input--email').classList.add('error')
                this.form.querySelector('.form__input--email .form__error').textContent = 'обзательное поле'
            };

            if (this.name.value == '') {
                error = true;
                this.form.querySelector('.form__input--name').classList.add('error')
            };

            if (this.text.value == '') {
                error = true;
                this.form.querySelector('.form__input--text').classList.add('error')
            };

            if (error) {
                return
            }


            let pathArray = window.location.pathname.split('/');
            let ex_slug = pathArray[pathArray.length - 1];

            const data = await api.addReview(ex_slug, this.name.value, this.email.value, this.ratingValue.textContent, this.text.value);
            this.form.querySelector('.review-form__body').remove();
            this.form.insertAdjacentHTML('beforeend',
                `<div class="review-form__body">
                          <h3 class="h3-title">${data.message}</h3>
                          <p>Как только мы проверим, что это не спам, он появится здесь</p>
                        </div>
                        `
            )
        });

        this.rating.onclick = (e) => {
            let rect = e.currentTarget.getBoundingClientRect();
            let width = Math.floor(100 * (e.clientX - rect.left) / rect.width);
            let rating_value = 0

            if (width >= 0 && width <= 20) {
                e.currentTarget.querySelector('.rating__fill').style.width = '20%'
                rating_value = 1
            }
            if (width >= 20 && width <= 40) {
                e.currentTarget.querySelector('.rating__fill').style.width = '40%'
                rating_value = 2

            }
            if (width >= 40 && width <= 60) {
                e.currentTarget.querySelector('.rating__fill').style.width = '60%'
                rating_value = 3

            }
            if (width >= 60 && width <= 80) {
                e.currentTarget.querySelector('.rating__fill').style.width = '80%'
                rating_value = 4

            }
            if (width >= 80 && width <= 100) {
                e.currentTarget.querySelector('.rating__fill').style.width = '100%'
                rating_value = 5
            }

            this.ratingValue.textContent = rating_value
        }

    },

}

let FormBook = {
    form: document.querySelector('.book-form'),
    btn: document.querySelector('.book-form .btn[type="submit"]'),
    counters: document.querySelectorAll('.book-form .counter'),
    total: document.querySelector('.book-form__total-value span'),
    program: document.querySelector('select[name="program-name"]'),
    child: document.querySelector('.counter[data-type="child"] input'),
    adult: document.querySelector('.counter[data-type="adult"] input'),


    init: async function () {
        this.form.addEventListener('submit', async (e) => {
            e.preventDefault();
            if (this.btn.classList.contains('btn--green')) {
                Cart.open();
                return;

            }
            let sum = this.total.textContent;

            let pathArray = window.location.pathname.split('/');
            let ex_slug = pathArray[pathArray.length - 1];

            let program_id = this.program.value;
            let program_name = this.program.options[this.program.selectedIndex].text;

            const resApi = await api.addBooking(ex_slug, this.child.value, this.adult.value, program_id, program_name, sum);


            if (resApi.message.trim() == 'ok') {

                this.btn.classList.remove('btn--magenta');
                this.btn.classList.add('btn--green');
                this.btn.textContent = 'В корзине';

                Header.showValue();
                Header.setValue(resApi.cnt);

            }


        });

        this.counters.forEach(c => {
            const plusBtn = c.querySelector('.counter__btn.plus')
            const minusBtn = c.querySelector('.counter__btn.minus')
            const input = c.querySelector('input')

            const type = c.dataset.type;
            const subtotal = document.querySelector(`.book-form__subtotal[type=${type}]`)
            const price = parseInt(subtotal.querySelector(`.book-form__price span`).textContent) || 0
            const subtotal_sum = subtotal.querySelector(`.book-form__sum span`)



            plusBtn.onclick = () => {
                const count = parseInt(input.value) || 0;

                input.value = count + 1;

                subtotal_sum.textContent = price * parseInt(input.value)

                calcTotal()
                if (count + 1 > 0) {
                    minusBtn.classList.remove('counter__btn--disabled');
                }
            }

            minusBtn.onclick = () => {
                const count = parseInt(input.value) || 0;

                input.value = count - 1;

                subtotal_sum.textContent = price * parseInt(input.value)

                calcTotal()

                if (count - 1 == 0) {
                    minusBtn.classList.add('counter__btn--disabled');
                }
            }



            input.addEventListener('keypress', (e) => {
                allowNumbersOnly(e);
            });

            input.addEventListener('keyup', (e) => {
                if (input.value == '') {
                    input.value = 0;
                } else {
                    input.value = parseInt(input.value);

                }


                const count = parseInt(input.value);
                subtotal_sum.textContent = price * count
                calcTotal()


                if (count > 0) {
                    minusBtn.classList.remove('counter__btn--disabled');
                } else {
                    minusBtn.classList.add('counter__btn--disabled');
                }
            });

            input.value = 0
        })

    },

}

let FormCheckout = {
    checkout: document.querySelector('.checkout'),
    checkoutContainer: document.querySelector('.checkout .container-fluid'),
    form: document.querySelector('.checkout-form'),

    name: document.querySelector('input[name="name"]'),
    email: document.querySelector('input[name="email"]'),
    phone: document.querySelector('input[name="phone"]'),
    text: document.querySelector('textarea[name="text"]'),
    agree: document.querySelector('input[name="agree"]'),

    init: async function () {
        this.name.oninput = (e) => {
            e.currentTarget.closest('.form__input').classList.remove('error')
        }

        this.email.oninput = (e) => {
            e.currentTarget.closest('.form__input').classList.remove('error')
        }

        this.text.oninput = (e) => {
            e.currentTarget.closest('.form__input').classList.remove('error')
        }

        this.phone.oninput = (e) => {
            e.currentTarget.closest('.form__input').classList.remove('error')
        }

        this.agree.onchange = (e) => {
            e.currentTarget.closest('.form__checkbox').classList.remove('error')
        }

        this.form.addEventListener('submit', async (e) => {
            e.preventDefault();
            let error = false;
            // console.log(this.form["payment"].value);


            if (!validEmail.test(this.email.value) && this.email.value !== '') {
                error = true;
                this.form.querySelector('.form__input--email').classList.add('error')
                this.form.querySelector('.form__input--email .form__error').textContent = 'введите правильный email'
            };

            if (this.email.value == '') {
                error = true;
                this.form.querySelector('.form__input--email').classList.add('error')
                this.form.querySelector('.form__input--email .form__error').textContent = 'обязательное поле'
            };

            if (this.name.value == '') {
                error = true;
                this.form.querySelector('.form__input--name').classList.add('error')
            };

            if (this.phone.value == '') {
                error = true;
                this.form.querySelector('.form__input--phone').classList.add('error')
            };

            if (this.text.value == '') {
                error = true;
                this.form.querySelector('.form__input--text').classList.add('error')
            };

            if (!this.agree.checked) {
                error = true;
                this.form.querySelector('.form__checkbox--agree').classList.add('error')

            }


            if (error) {
                return
            }


            const resApi = await api.addCheckout(
                this.form["connection"].value
                , this.form["text"].value
                , this.form["payment"].value
                , this.form["email"].value
                , this.form["name"].value
                , this.form["phone"].value
            );


            if (resApi.message.trim() == 'ok') {

                this.checkout.querySelector('h1').remove()
                // Header.hideValue()
                this.checkout.querySelector('.checkout__inner').remove()
                this.checkoutContainer.insertAdjacentHTML('beforeend',
                    ` <div class="checkout__confirm">
                <h1 class="h1-title">
                  Спасибо
                </h1>
                <h2 class="h2-title">
                  Ваша заявка принята
                </h2>
                <p>Вам на почту придут данные о заказе</p>
                <a href="/" class="btn btn--magenta">Вернуться на главную</a>
              </div>
            `
                )
            }


        });


    },

}


function calcTotal() {
    let sum = 0
    const subtotals = document.querySelectorAll('.book-form__subtotal .book-form__sum span');
    subtotals.forEach(s => {
        sum = sum + parseInt(s.textContent);
    })

    document.querySelector('.book-form__total-value span').textContent = sum

}


export { FormReview, FormBook, FormCheckout }
